<template>
  <hcc-modal
    :title="$t('users.create')"
    class="user"
    :width="600"
    name="Usuario"
    @confirm="userAction"
    @closed="clear"
    @cancel="clear"
    @opened="focusBuildModal"
    :confirmDisableButton="!validForm"
  >
    <div class="user__property">
      <hcc-input
        :placeholder="$t('common.name')"
        v-model="name"
        @blur="$v.name.$touch()"
        :error="$v.name.$error"
        :label="$t('common.name')"
        ref="hccInput"
      />
      <p v-if="$v.name.$error" class="user__error">
        {{ $t("users.errors.name") }}
      </p>
      <span />
      <hcc-select
        v-if="isOwner"
        :label="$t('filter.company')"
        custom-class="select"
        v-model="company"
        :options="companies"
        option-label="name"
        track-by="id"
      />
      <hcc-select
        :label="$t('users.profile')"
        custom-class="select"
        v-model="role"
        :options="roles"
        :error="$v.role.$error"
        option-label="display_name"
        track-by="id"
      />
      <hcc-input
        :placeholder="$t('common.email')"
        v-model="email"
        @blur="$v.email.$touch()"
        :error="$v.email.$error"
        :label="$t('common.email')"
      />
      <template v-if="$v.email.$error">
        <p v-if="!$v.email.email" class="user__error">
          {{ $t("users.errors.email") }}
        </p>
        <p v-if="!$v.email.required" class="user__error">
          {{ $t("users.errors.email2") }}
        </p>
      </template>
      <span />
      <hcc-input
        type="password"
        :placeholder="$t('users.password')"
        v-model.trim="password"
        @blur="$v.password.$touch()"
        :error="$v.password.$error && !edit"
        :label="$t('users.password')"
      />
      <template v-if="$v.password.$error">
        <p v-if="!$v.password.minLength" class="user__error">
          {{ $t("users.errors.password-min") }}
        </p>
        <p v-if="!$v.password.required && !edit" class="user__error">
          {{ $t("users.errors.password") }}
        </p>
        <p v-if="!$v.password.valid" class="user__error">
          {{ $t("users.errors.valid-password") }}
        </p>
      </template>
      <span />
      <hcc-input
        type="password"
        :placeholder="$t('users.password-confirm')"
        v-model="confirm"
        @blur="$v.confirm.$touch()"
        :error="$v.confirm.$error"
        :label="$t('users.password-confirm')"
        @keyup.enter="submit"
      />
      <template v-if="$v.confirm.$error">
        <p v-if="!$v.confirm.sameAsPassword" class="user__error">
          {{ $t("users.errors.confirm") }}
        </p>
      </template>
    </div>
  </hcc-modal>
</template>

<script>
import {
  required,
  email,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import { NEW_USER } from '@/eventTypes';
import { validPassword } from '@/utils/helper';

export default {
  props: {
    user: {
      required: true,
      type: Object,
    },
    edit: {
      required: true,
      type: Boolean,
    },
    userCompany: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      company: { id: '' },
      name: '',
      email: '',
      password: null,
      confirm: null,
      role: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
      valid: password => validPassword(password),
    },
    confirm: {
      sameAsPassword: sameAs('password'),
    },
    name: { required },
    role: { required },
  },
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
  },
  computed: {
    ...mapGetters({
      companies: 'shared/getFilterCompanies',
      isOwner: 'shared/isOwner',
      roles: 'shared/getRoles',
    }),
    companyID() {
      return this.company.id ? this.company.id : this.userCompany;
    },
    validForm() {
      return this.edit ? this.userEdited : !this.$v.$invalid;
    },
    userEdited() {
      return this.user.name !== this.name || this.user.email !== this.email
       || (this.company.id !== this.user.company_id) || (this.password && this.confirm
          && this.$v.password.minLength && this.$v.confirm.sameAsPassword)
            || this.role.name !== this.user.role.name;
    },
  },
  methods: {
    buildUserForm() {
      this.company = this.findCompany(this.userCompany);
      this.name = this.user.name;
      this.email = this.user.email;
      this.role = this.user.roles;
    },
    userAction() {
      let payload = {
        name: this.name,
        email: this.email,
        roles: this.role.id,
        company: this.companyID,
      };

      if (this.password) {
        payload = {
          ...payload,
          password: this.password,
        };
      }

      if (this.edit) {
        payload = {
          ...payload,
          id: this.user.id,
        };
      }

      EventBus.$emit(NEW_USER, { payload, role: this.role?.name });
    },
    clear() {
      this.$v.$reset();
      this.company = { id: '' };
      this.name = '';
      this.email = '';
      this.password = null;
      this.confirm = null;
      this.role = null;
      this.$modal.hide('Usuario');
    },
    createNew() {
      this.company = this.findCompany(this.companyID);
      this.role = {
        ...this.roles[0],
      };
    },
    findCompany(id) {
      return this.companies.length > 0
        ? this.companies.find(c => c.id === id)
        : { id };
    },
    focusBuildModal() {
      if (this.edit) {
        this.buildUserForm();
      } else {
        this.createNew();
      }
      this.$refs.hccInput.focus();
    },
    submit() {
      if (this.validForm) {
        this.userAction();
        this.$modal.hide('Usuario');
      }
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
</style>
